import {Component, Input, OnInit} from '@angular/core';
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";
import {AppContext} from "../../../../app-context";
import {
  DeleteUser,
  DeploymentKeyResult, GenerateApiKey,
  Organisation,
  Team,
  UserProfile
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable} from "rxjs";
import {UserDetailsComponent} from "../../../users/user-details/user-details.component";
import {downloadAny} from "../../../../common/download.utils";

@Component({
  selector: 'app-api-key-overview-item',
  templateUrl: './api-key-overview-item.component.html',
  styleUrls: ['./api-key-overview-item.component.scss']
})
@Handler()
export class ApiKeyOverviewItemComponent extends View implements OnInit {
  appContext = AppContext;
  @Input() user: UserProfile;
  organisations: Observable<Organisation[]>;
  organisation: Organisation;

  ngOnInit() {
    this.organisations = this.subscribeTo("getOrganisations");
    this.subscribeTo("getOrganisation").subscribe((o: Organisation) => this.organisation = o);
  }

  openKeyDetails = () => this.openModal(UserDetailsComponent, this.user);

  cycleDeploymentKey = () => {
    this.sendQuery("getTeam").subscribe((team: Team) =>
      this.sendCommand("host.flux.service.authentication.api.GenerateApiKey", <GenerateApiKey>{
        userId: this.user.userId,
        teamId: team.teamId
      }, (result : DeploymentKeyResult) => downloadAny(
        JSON.stringify(result), `${result.keyId}.json`, "application/json")));
  }

  deleteApiKey = () => this.sendCommand("host.flux.service.user.api.DeleteUser", <DeleteUser>{
    userId: this.user.userId
  });
}
